<template>
  <aside class="md-layout-sidebar">
    <div class="info">
      <div>
        <img class="avater" v-if="userInfo?.avatar" :src="`/video-dev${userInfo?.avatar}`" alt="">
        <img class="avater" v-else src="@/assets/img/avatar.jpeg" alt="">
        <p style="text-align: center;font-size: 16px;margin-top: 13px;">{{ userInfo?.userName }}</p>
        <p></p>
      </div>
    </div>
    <ul>
      <li v-for="item in menus" :key="item.value" :class="[current === item.path ? 'current' : '']"
        @click="handleRouter(item)">
        {{ item.label }}
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: 'MdLayoutSidebarComponent',
  data() {
    return {
      current: null,
      userInfo: {},
      menus: [
        {
          value: 1,
          label: '我的课程',
          path: '/manage/guankanjilu',
          icon: 'imv2-play_circle'
        },
        {
          value: 2,
          label: '收藏课程',
          path: '/manage/shoucangjilu',
          icon: 'imv2-star-revert'
        },
        {
          value: 3,
          label: '答题记录',
          path: '/manage/datijilu',
          icon: 'imv2-nav_note'
        },
        {
          value: 4,
          label: '个人信息',
          path: '/manage/gerenziliao',
          icon: 'imv2-list_bulleted'
        },
        {
          value: 5,
          label: '酷学证书',
          path: '/manage/zhengshu',
          icon: 'el-icon-reading'
        }
      ]
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.handleSetMenuCurrent(route)
      }
    }
  },
  mounted() {
    window.addEventListener("setItemEvent", (e) => {
      if(e.key=='userInfo'){
      this.userInfo = JSON.parse(e.newValue)
     }
    });
    this.handleSetMenuCurrent(this.$route)
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  },
 
  methods: {
    handleSetMenuCurrent(route) {
      console.log(route)
      this.current = route.path
    },
    handleRouter(item) {
      this.$router.push(item.path)
    }
  },
  computed: {
    isLogin() {
      return window.localStorage.getItem('isLogin')
    },

  }
}
</script>

<style lang="less" scoped>
.md-layout-sidebar {
  width: @md-manage-sidebar-width;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 12px;
  background-color: #fff;

  .info {
    width: 210px;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EBEBEB;

    .avater {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  }

  ul {
    li {
      width: 100%;
      line-height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing:border-box;
      font-size: 16px;
      text-align: center;
      color: #787d82;
      position: relative;
      margin-bottom: 10px;
      cursor: pointer;

      &.current {
        color: @md-primary-color;
        // border-radius: 8px 0px 0px 8px;
        // color: #fff;
        border-left: 3px solid @md-primary-color;

        i {
          color: #fff;
        }

        b {
          display: block;
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          width: 0;
          height: 0;
          border-top: 24px solid #f8fafc;
          border-bottom: 24px solid #f8fafc;
          border-left: 15px solid transparent;
        }
      }

      i {
        font-size: 20px;
        color: #93999f;
        margin-right: 10px;
      }

      b {
        display: none;
      }
    }
  }
}</style>
