<template>
  <div class="md-layout">
   <md-layout-header />
    <!-- <md-layout-info />  -->
    <section class="md-layout-section">
      <md-layout-sidebar />
      <main class="md-layout-main">
        <router-view />
      </main>
    </section>
    <md-shared-footer />
  </div>
</template>

<script>
import MdLayoutHeaderComponent from './header';
import MdLayoutSidebarComponent from './sidebar';
// import MdLayoutInfoComponent from './info.vue';
import MdSharedFooterComponent from '../footer'
export default {
  name: 'MdLayoutComponent',
  components: {
    'md-layout-header': MdLayoutHeaderComponent,
    'md-layout-sidebar': MdLayoutSidebarComponent,
    // 'md-layout-info': MdLayoutInfoComponent,
    'md-shared-footer': MdSharedFooterComponent
  },
  mounted () {
    this.$nextTick(() => {
      const htmlDOM = window.document.querySelector('html')
      htmlDOM.className = "common"
    })
  },
};
</script>

<style lang="less" scoped>
.md-layout {
  width: 100%;
  // height: 100%;
  box-sizing: border-box;
  background-color: @md-bgc-primary-color;
  // padding-top: @md-header-height;

  .md-layout-section {
    width: @md-manage-main-width;
    margin: 20px auto auto auto;
    display: flex;
    .md-layout-main {
      flex: 1;
      box-sizing: border-box;
      padding: 0px 24px 20px 24px;
      overflow-x: hidden;
      overflow-y: auto;
      .scroll-bar(5px,5px);
    }
  }
}
</style>